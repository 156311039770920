
body {
	background: #f0f0f4;
	padding: 40px!important;
}


#main-container > header {
	background: #fff;
	padding: 40px;
	display: table;
	width: 100%;
	position: relative;

	& > * {
		display: table-cell;
		vertical-align: middle;
	}

	h1 {
		padding-right: 10px;

		svg {
			width: 200px;
			* {
				fill: #1f2223!important;
			}
		}
	}

	ul {
		list-style: none;
		text-align: right;
	}

	li  {
		display: inline-block;
		color: #333;
		padding: 10px;
 
		a {
			text-decoration: none;
			display: inline-block;
			// padding: 2px 5px;
			position: relative;

			&.active {
				color: #006095;
				cursor: default;
			}
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				bottom: -5px;
				height: 2px;
				background: #006095;
				opacity: 0;
				transition: opacity .3s ease;
			}

			&:hover,
			&.active {
				&:before {
					opacity: 1;
				}
			}
		}
	}


}

.module {
	background:#fff;
	padding: 40px;
	margin: 20px 0;

	&:last-child {
		margin-bottom: 0;
	}

	&__header {
		margin-bottom: 40px;

		h2 {
			font-size: 35px;
			text-transform: capitalize;

			+ p {
				margin-top: 20px;
				padding-bottom: 30px;
				border-bottom: 1px solid #efefef;
			}
		}
	}

	&__component {
		&:not(:first-child) {
			margin-top: 40px;
		}

		.culture-hero .feat-image {
			margin-top: 0!important;
		}
	}

	&__parameter,
	pre {
		padding: 15px!important;
		border-radius: 4px;
	}

	pre {
		background: #333;
		color: #fff;
		font-size: 18px;
		line-height: 1.5!important;
	}

	.param-title {
		font-size: 16px;
		margin: 40px 0 20px;
		color: #333;
		text-transform: capitalize;
	}

	&__parameter {
		font-size: 14px;
		background: #F5F5F5;

		.parameter {
			color: #333;
			font-size: 13px;
			display: block;
			margin-bottom: 10px;

			span {
				font-size: 16px;
				color: #555;
				font-style: italic;
			}

			&:not:last-child {
				margin-bottom: 20px;
			}
		}

		& + pre {
			margin-top: 20px;
		}
	}
}

.clear {
	clear: both;
}

// reset module

body {
	&.style-guide--global {
		.highlighted-stories {
			margin: 0;
			padding: 0;
		}
		.footer-newsletter-form-container {
			max-width: 600px;
		}
		.filter-attributes {
			margin: 0;
		}
		.brand-listing-products-container {
			padding: 0;
			margin-bottom: 30px;
		}
	}
	&.style-guide--home {
		.home-shop-mens-womens {
			margin-bottom: 0!important;
		}
		.highlighted-stories {
			margin: 0!important;
		}
		.team-picks {
			min-height: 700px!important;
			margin-bottom: 0!important;
		}
	}
	&.style-guide--category-landing {
		.brand-collection-container {
			margin: 0;
			padding: 0;
			&:after,
			&:before {
				display: none!important;
			}
		}
		.collection-cell {
			margin-bottom: 0!important;
		}
	}
	&.style-guide--culture {
		.latest-stories-container {
			margin: 0!important;
			padding: 0!important;
		}
		.share-link:hover i {
			color: #fff;
		}
		.looks-container {
			margin: 0!important;
			padding: 0!important;
			overflow-x: hidden;
			overflow-y: visible;
		}
		.sds-live-container {
			padding-top: 0px!important;
		}
		.tv-cotainer .main-video {
			margin: 0!important;
		}

	}
	&.style-guide--brands {
		.container-fluid {
			padding: 0!important;
			margin-bottom: -20px;
			h1 {
				margin-top: 0px!important;
			}
			.brand-feature-large,
			.brand-feature-medium {
				padding: 0!important;
			}
		}
		.category-header {
			margin: 0!important;
		}
		.header-image-full {
			z-index: 0!important;
		}
		[data-view='Global.Breadcrumb'] {
			position: relative;
		}
		.category-popular-container {
			padding: 0;
			border-bottom: 0;
		}
		.paginate {
			border: 0;
			padding: 0;
			float: left;
		}
	}
	&.style-guide--collections {
		.featured-category {
			padding: 0;
		}
         .collection-landing-container {
         	border-left: none;
		    margin-left: 0;
		    padding-bottom: 0;
         }
         .header-image-full {
			z-index: 1;
         }
         [data-view='Global.Breadcrumb'],
         .back-to-prev-category {
			position: relative;
			z-index: 1;
		}
		.category-header div:last-child,
		.copy-block {
			position: relative;
			z-index: 2;
		}
		.product-palette-container {
			padding: 0 0 75px;
		}
		.product-lifestyle {
			margin: 0 0 100px;
		}
	}
	&.style-guide--pdp {
		.reviews-header {
			margin-bottom: 0;
		}
		.recently-viewed .modal .modal-dialog {
			top: 0!important;
		}
		.product-header {
			position: relative!important;
			transform: none!important;
		}
		.product-reviews {
			padding: 0!important;
			margin: 0!important;
		}
	}
	&.style-guide--sales {
		.category-row:last-child {
			margin-bottom: 10px;
		}
		.filter-set {
			margin: 0;
			padding: 0;
			padding-bottom: 100px;
		}
		.category-container {
			max-width: 1400px;
			display: inline-block;
		}
	}
	&.style-guide--team {
		.module--last .team-container,
		.team-row {
			margin: 0;
			padding: 0;
		}
	}


}
